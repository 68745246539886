import { FunctionComponent } from 'react';
import { LabelledInfoCell } from '../../sharedComponents/sidebar/LabelledInfoCell';
import { Arrival, CompoundLocation } from '../compoundManagement.types';
import { DisplayFreightForwarder } from '../shared/dataDisplay/DisplayFreightForwarder';
import { DisplayCancellableData } from '../shared/dataDisplay/DisplayCancellableData';
import { ArrivalCompletedForm } from '../shared/sidebar/ArrivalCompletedForm';

export type ArrivalDetailsProps = {
    compoundVehicleId: string;
    arrival: Arrival;
    compoundLocation: CompoundLocation;
};

export const ArrivalDetails: FunctionComponent<ArrivalDetailsProps> = ({
    compoundVehicleId,
    arrival,
    compoundLocation,
}) => {
    return (
        <ArrivalCompletedForm
            timeZone={compoundLocation.timeZone}
            compoundVehicleId={compoundVehicleId}
            disabled={arrival.isCancelled || arrival.isFinalized}
            arrivalCompletedAt={arrival.arrivalCompletedAt}
        >
            <LabelledInfoCell labelMessageId="outboundOrderBook.compoundManagement.table.heading.carrier">
                <DisplayCancellableData isCancelled={arrival.isCancelled}>
                    <DisplayFreightForwarder freightForwarder={arrival.freightForwarder} />
                </DisplayCancellableData>
            </LabelledInfoCell>
        </ArrivalCompletedForm>
    );
};
