import classNames from 'classnames';
import { FunctionComponent } from 'react';
import { NoValue } from '../../../sharedComponents/NoValue';
import { LabelledInfoCell } from '../../../sharedComponents/sidebar/LabelledInfoCell';
import { CompoundLocation, ExpectedArrival } from '../../compoundManagement.types';
import { DateTimeInfo } from '../../shared/dataDisplay/DateTimeInfo';
import { DisplayCancellableData } from '../../shared/dataDisplay/DisplayCancellableData';
import { DisplayFreightForwarder } from '../../shared/dataDisplay/DisplayFreightForwarder';
import { DisplayMeansOfTransportType } from '../../shared/dataDisplay/DisplayMeansOfTransportType';
import { ArrivalCompletedForm } from '../../shared/sidebar/ArrivalCompletedForm';

export type ExpectedArrivalDetailsProps = {
    compoundVehicleId: string;
    arrival: ExpectedArrival;
    compoundLocation: CompoundLocation;
};

export const ExpectedArrivalDetails: FunctionComponent<ExpectedArrivalDetailsProps> = ({
    compoundVehicleId,
    arrival,
    compoundLocation,
}) => {
    const rowClasses = 'display-flex gap-25 align-items-start';
    const expectedArrivalAt = arrival.expectedArrivalAt;
    const isCancelled = arrival.isCancelled;

    return (
        <>
            <div className={classNames(rowClasses, 'margin-bottom-15')}>
                <LabelledInfoCell labelMessageId="outboundOrderBook.compoundManagement.table.heading.expectedArrivalDate">
                    <DisplayCancellableData isCancelled={isCancelled}>
                        <DateTimeInfo date={expectedArrivalAt} timezone={compoundLocation?.timeZone} />
                    </DisplayCancellableData>
                </LabelledInfoCell>
                <LabelledInfoCell labelMessageId="outboundOrderBook.compoundManagement.sidebar.details.carrierAndTransportType">
                    <DisplayCancellableData isCancelled={isCancelled}>
                        <DisplayFreightForwarder freightForwarder={arrival.freightForwarder} />
                        {' / '}
                        <DisplayMeansOfTransportType meansOfTransportType={arrival.meansOfTransport.type} />
                    </DisplayCancellableData>
                </LabelledInfoCell>
            </div>
            <ArrivalCompletedForm
                timeZone={compoundLocation.timeZone}
                compoundVehicleId={compoundVehicleId}
                disabled={isCancelled}
                arrivalCompletedAt={undefined}
            >
                <LabelledInfoCell labelMessageId="outboundOrderBook.compoundManagement.sidebar.details.meansOfTransport">
                    <DisplayCancellableData isCancelled={isCancelled}>
                        {arrival.meansOfTransport.id ?? <NoValue />}
                    </DisplayCancellableData>
                </LabelledInfoCell>
            </ArrivalCompletedForm>
        </>
    );
};
