import type { BUTTON_STYLE } from '@rio-cloud/rio-uikit';

export type Address = {
    readonly name: string;
    readonly street: string;
    readonly postalCode: string;
    readonly city: string;
    readonly countryCode: string;
};

export type AlertConfig = {
    readonly highlight?: string;
    readonly rowIcon: string;
    readonly hintMessageKey: string;
};

export type ActionButtonConfig = {
    labelId: string;
    style: BUTTON_STYLE;
    className?: string;
    icon: string;
    expandSidebar: boolean;
    isSpinnerInverse: boolean;
};

export type FileExport = {
    fileLink: string;
    fileName: string | undefined;
};

export type Vehicle = {
    readonly id: VehicleId;
    readonly model: Model;
    readonly weight?: number;
    readonly colour?: string;
    readonly dimensions?: VehicleDimensions;
    readonly vehicleType?: VehicleType;
    readonly licensePlate?: string;
    readonly specialInstructions: string[];
    readonly deliveryConditions?: VehicleConditionType;
    readonly hasPriority?: boolean;
    readonly manufacturedAt?: Date;
    readonly finalDestination?: Address;
};

export type VehicleId = {
    readonly vin?: string;
    readonly productionNumber?: string;
};

export type Model = {
    readonly name: string;
    readonly modelGroup: string;
    readonly category?: ModelCategory;
    readonly manufacturer: string | undefined;
    readonly fuelType?: FuelType;
};

export enum ModelCategory {
    CAMPER_VAN = 'camper-van',
    CAR = 'car',
    MOTORCYCLE = 'motorcycle',
    QUAD = 'quad',
    SPECIAL_VEHICLE = 'special-vehicle',
    TRIKE = 'trike',
    TRUCK = 'truck',
    VAN = 'van',
}

export enum FuelType {
    DIESEL = 'diesel',
    DIESEL_ELECTRIC = 'dieselElectric',
    ELECTRIC = 'electric',
    LIQUID_PROPANE_GAS = 'liquidPropaneGas',
    PETROL = 'petrol',
    PETROL_ELECTRIC = 'petrolElectric',
    HYDROGEN = 'hydrogen',
    COMPRESSED_NATURAL_GAS = 'compressedNaturalGas',
    LIQUID_NATURAL_GAS = 'liquidNaturalGas',
}

export type VehicleDimensions = {
    readonly length?: number;
    readonly width?: number;
    readonly height?: number;
};

export enum VehicleType {
    NEW = 'new',
    USED = 'used',
    OTHER = 'other',
}

export enum VehicleConditionType {
    NOT_ON_SITE = 'not-on-site',
    CONTACT_UNKNOWN = 'contact-unknown',
    NOT_READY_FOR_PICKUP = 'not-ready-for-pickup',
    NO_KEYS = 'no-keys',
    NOT_READY_TO_DRIVE = 'not-ready-to-drive',
    NOT_READY_TO_MOVE = 'not-ready-to-move',
    OTHER = 'other',
}
