import { BUTTON_STYLE } from '@rio-cloud/rio-uikit/Button';
import { useAppDispatch } from '../../../../configuration/setup/hooks';
import { compoundManagementSlice } from '../../reducers/compoundManagementSlice';
import { ActionButtonConfig } from '../../sharedComponents/common.types';
import { TableActionButton } from '../../sharedComponents/table/TableActionButton';
import { CompoundVehicle } from '../compoundManagement.types';

type CompoundVehicleActionButtonProps = {
    labelId: string;
    icon: string;
    vehicle: CompoundVehicle;
    buttonStyle: BUTTON_STYLE;
};

export const CompoundVehicleActionButton = ({
    labelId,
    vehicle,
    icon,
    buttonStyle,
}: CompoundVehicleActionButtonProps) => {
    const dispatch = useAppDispatch();

    const actionButtonConfig: ActionButtonConfig = {
        labelId,
        expandSidebar: true,
        style: buttonStyle,
        icon: `rioglyph ${icon}`,
        isSpinnerInverse: true,
    };

    const handleActionClick = () => dispatch(compoundManagementSlice.actions.selectCompoundVehicle(vehicle));

    return <TableActionButton actionButtonConfig={actionButtonConfig} onClick={handleActionClick} isLoading={false} />;
};
