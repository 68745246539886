import * as t from 'io-ts';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { reportErrorToSentry } from '../../../configuration/setup/sentry';
import { FormattedMessage } from 'react-intl';
import { SerializedError } from '@reduxjs/toolkit';
import { ProblemCodec } from '../api/types/problemApi.types';

export const isErrorResponse = <CODEC extends t.Type<any, any, any>>(
    error: unknown,
    dataCodec: CODEC,
): error is t.TypeOf<typeof FetchBaseQueryErrorCodec> & { data: t.TypeOf<CODEC> } =>
    FetchBaseQueryErrorCodec.is(error) && dataCodec.is(error.data);

// We intentionally explicitly type this codec to make sure the
// built runtime representation matches the compile time type from
// the external dependency.
const FetchBaseQueryErrorCodec: t.Type<FetchBaseQueryError> = t.union([
    t.type({
        status: t.number,
        data: t.unknown,
    }),
    t.intersection([
        t.type({
            status: t.literal('FETCH_ERROR'),
            error: t.string,
        }),
        t.partial({
            data: t.undefined,
        }),
    ]),
    t.type({
        status: t.literal('PARSING_ERROR'),
        originalStatus: t.number,
        data: t.string,
        error: t.string,
    }),
    t.intersection([
        t.type({
            status: t.literal('TIMEOUT_ERROR'),
            error: t.string,
        }),
        t.partial({
            data: t.undefined,
        }),
    ]),
    t.intersection([
        t.type({
            status: t.literal('CUSTOM_ERROR'),
            error: t.string,
        }),
        t.partial({
            data: t.unknown,
        }),
    ]),
]);

const getErrorMessageForFetchBaseQuery = (error: FetchBaseQueryError) => {
    switch (error.status) {
        case 'PARSING_ERROR':
        case 'FETCH_ERROR':
        case 500: {
            return 'outboundPortal.notification.technicalError';
        }
        case 401:
        case 403:
            return 'outboundPortal.notification.unauthorizedError';
        default: {
            if (ProblemWithDetailCodec.is(error.data)) {
                if (error.data.detail.startsWith('Pick up confirmation failed')) {
                    return 'outboundPortal.notification.confirmTransportAssignment.unconfirmableState';
                }
                if (error.data.detail.startsWith('An unloading address must')) {
                    return 'outboundPortal.notification.confirmUnloading.notProvidedAddress';
                }
            }
            return 'outboundPortal.notification.technicalError';
        }
    }
};

const ProblemWithDetailCodec = t.intersection([
    ProblemCodec,
    t.type({
        detail: t.string,
    }),
]);

const reportRequestErrorToSentry = (error: FetchBaseQueryError) => {
    if (error.status === 500 || error.status === 'PARSING_ERROR' || error.status === 'FETCH_ERROR') {
        reportErrorToSentry(error);
    }
};

export const getErrorMessage = (error: FetchBaseQueryError | undefined | SerializedError) => {
    if (FetchBaseQueryErrorCodec.is(error)) {
        return getErrorMessageForFetchBaseQuery(error);
    } else {
        return 'intl-msg:common-message.error.generic.retryOrSupport';
    }
};

export const handleQueryError = (error: unknown) => {
    if (FetchBaseQueryErrorCodec.is(error)) {
        showErrorNotification(getErrorMessageForFetchBaseQuery(error));
        reportRequestErrorToSentry(error);
    } else {
        reportErrorToSentry(error);
    }
};

export const showErrorNotification = (messageId: string, values?: Record<string, string>) => {
    return Notification.error(
        <span>
            <FormattedMessage id={messageId} values={values} />
            <span className="notification-close">
                <span className="rioglyph rioglyph-remove" />
            </span>
        </span>,
        '',
        999999,
        () => {},
    );
};
