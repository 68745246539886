// this utility function can be used to turn a TypeScript enum into an io-ts codec.
import * as t from 'io-ts';
import * as F from 'fp-ts';

export const fromEnum = <EnumType>(enumName: string, theEnum: Record<string, string | number>) => {
    const isEnumValue = (input: unknown): input is EnumType => Object.values<unknown>(theEnum).includes(input);

    return new t.Type<EnumType>(
        enumName,
        isEnumValue,
        (input, context) => (isEnumValue(input) ? t.success(input) : t.failure(input, context)),
        t.identity,
    );
};

export const validateResponse = (decoder: t.Decoder<unknown, unknown>) => (response: Response, result: unknown) => {
    if (!response.ok) {
        return false;
    }
    return decodeResponse(decoder, F.function.constTrue)(result);
};

export const decodeResponse =
    <T, P>(decoder: t.Decoder<unknown, T>, mapper: (payload: T) => P) =>
    (result: unknown): P => {
        const formatIoTsErrors = (errors: t.Errors) =>
            errors.map((error) => error.context.map(({ key }) => key).join('.')).join('\n');

        return F.function.pipe(
            result,
            decoder.decode,
            F.either.map(mapper),
            F.either.mapLeft(formatIoTsErrors),
            F.either.getOrElseW((errors) => {
                throw new Error(`Error during parsing response: ${errors}`);
            }),
        );
    };
