import Button from '@rio-cloud/rio-uikit/Button';
import classNames from 'classnames';
import { FunctionComponent, PropsWithChildren, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { runInBackgroundCallback } from '../../../../../configuration/setup/backgroundActions';
import { useAppDispatch } from '../../../../../configuration/setup/hooks';
import { usePutDepartureCompletionMutation } from '../../../api/compoundManagementApi';
import { MissingVehicleInInventoryProblemCodec } from '../../../api/types/compoundManagement/compoundManagementApi.types';
import { handleQueryError, isErrorResponse, showErrorNotification } from '../../../notifications/ErrorNotification';
import { showSuccessNotification } from '../../../notifications/SuccessNotification';
import { compoundManagementSlice } from '../../../reducers/compoundManagementSlice';
import { CompletedStatusAtInput } from './CompletedStatusAtInput';
import { getStatusDateValidationMessageId } from './validateStatusDate';

type DepartureCompletedFormProps = PropsWithChildren<{
    timeZone: string;
    compoundVehicleId: string;
    disabled: boolean;
    departureCompletedAt: Date | undefined;
}>;

export const DepartureCompletedForm: FunctionComponent<DepartureCompletedFormProps> = ({
    timeZone,
    compoundVehicleId,
    disabled,
    children,
    departureCompletedAt: previousDepartureCompletedAt,
}) => {
    const dispatch = useAppDispatch();
    const [completeDeparture, completeDepartureRequest] = usePutDepartureCompletionMutation();

    const [submitted, setSubmitted] = useState(false);
    const [departureCompletedAt, setDepartureCompletedAt] = useState<Date | string | undefined>(
        previousDepartureCompletedAt,
    );

    const isUpdate = previousDepartureCompletedAt !== undefined;

    const handleConfirmDeparture = runInBackgroundCallback(async () => {
        setSubmitted(true);

        const isStatusValid = getStatusDateValidationMessageId(departureCompletedAt) === undefined;

        if (isStatusValid) {
            try {
                await completeDeparture({
                    compoundVehicleId,
                    departedStatusChange: { statusAt: departureCompletedAt as Date },
                }).unwrap();

                showSuccessNotification(
                    isUpdate
                        ? 'outboundOrderBook.compoundManagement.notification.departedVehicle.updateDeparture.success'
                        : 'outboundOrderBook.compoundManagement.notification.outgoingVehicle.confirmDeparture.success',
                );
                dispatch(compoundManagementSlice.actions.deselectCompoundVehicle());
            } catch (error: unknown) {
                handleDepartureCompletionError(error);
            }
        }
    });

    const rowClasses = 'display-flex gap-25 align-items-start';

    return (
        <>
            <div className={rowClasses}>
                <CompletedStatusAtInput
                    labelId="outboundOrderBook.compoundManagement.table.heading.departureDate"
                    timeZone={timeZone}
                    disabled={disabled}
                    statusDate={departureCompletedAt}
                    onChangeStatusDate={setDepartureCompletedAt}
                    validationErrorId={submitted ? getStatusDateValidationMessageId(departureCompletedAt) : undefined}
                />
                {children}
            </div>
            <hr />
            <div className="display-flex gap-25 justify-content-end">
                {isUpdate ? (
                    <UpdateDepartureButton
                        disabled={disabled}
                        isLoading={completeDepartureRequest.isLoading}
                        onClick={handleConfirmDeparture}
                    />
                ) : (
                    <ConfirmDepartureButton
                        disabled={disabled}
                        isLoading={completeDepartureRequest.isLoading}
                        onClick={handleConfirmDeparture}
                    />
                )}
            </div>
        </>
    );
};

const ConfirmDepartureButton: FunctionComponent<{ disabled: boolean; isLoading: boolean; onClick: () => void }> = ({
    disabled,
    isLoading,
    onClick,
}) => (
    <Button
        className={classNames(isLoading ? 'btn-loading' : '')}
        disabled={disabled || isLoading}
        iconName="rioglyph-load-unload"
        bsStyle={Button.PRIMARY}
        onClick={onClick}
    >
        <FormattedMessage id="outboundPortal.compoundManagement.button.confirmDeparture" />
    </Button>
);

const UpdateDepartureButton: FunctionComponent<{ disabled: boolean; isLoading: boolean; onClick: () => void }> = ({
    disabled,
    isLoading,
    onClick,
}) => (
    <Button
        className={classNames(isLoading ? 'btn-loading' : '')}
        disabled={disabled || isLoading}
        iconName="rioglyph-load-unload"
        bsStyle={Button.DEFAULT}
        onClick={onClick}
    >
        <FormattedMessage id="outboundPortal.compoundManagement.button.updateDeparture" />
    </Button>
);

const handleDepartureCompletionError = (error: unknown) => {
    if (isErrorResponse(error, MissingVehicleInInventoryProblemCodec)) {
        showErrorNotification(
            'outboundOrderBook.compoundManagement.notification.outgoingVehicle.confirmDeparture.missingVehicleInInventory',
            { vin: error.data.vin },
        );
    } else {
        handleQueryError(error);
    }
};
