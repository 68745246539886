import * as t from 'io-ts';

export const ProblemCodec = t.partial({
    type: t.string,
    title: t.string,
    status: t.number,
    detail: t.string,
    instance: t.string,
});

export const ProblemWithErrorCodeCodec = t.intersection([
    ProblemCodec,
    t.type({
        error_code: t.string,
    }),
]);

export type Problem = t.TypeOf<typeof ProblemCodec>;
export type ProblemWithErrorCode = t.TypeOf<typeof ProblemWithErrorCodeCodec>;
