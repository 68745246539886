import classNames from 'classnames';
import { FunctionComponent } from 'react';
import { NoValue } from '../../../sharedComponents/NoValue';
import { LabelledInfoCell } from '../../../sharedComponents/sidebar/LabelledInfoCell';
import { CompoundLocation, ExpectedDeparture } from '../../compoundManagement.types';
import { DateTimeInfo } from '../../shared/dataDisplay/DateTimeInfo';
import { DisplayCancellableData } from '../../shared/dataDisplay/DisplayCancellableData';
import { DisplayFreightForwarder } from '../../shared/dataDisplay/DisplayFreightForwarder';
import { DisplayMeansOfTransportType } from '../../shared/dataDisplay/DisplayMeansOfTransportType';
import { DepartureCompletedForm } from '../../shared/sidebar/DepartureCompletedForm';

export type ExpectedDepartureDetailsProps = {
    compoundVehicleId: string;
    departure: ExpectedDeparture;
    compoundLocation: CompoundLocation;
};

export const ExpectedDepartureDetails: FunctionComponent<ExpectedDepartureDetailsProps> = ({
    compoundVehicleId,
    departure,
    compoundLocation,
}) => {
    const rowClasses = 'display-flex gap-25 align-items-start';
    const expectedDepartureAt = departure.expectedDepartureAt;
    const isCancelled = departure.isCancelled;

    return (
        <>
            <div className={classNames(rowClasses, 'margin-bottom-15')}>
                <LabelledInfoCell labelMessageId="outboundOrderBook.compoundManagement.table.heading.expectedDepartureDate">
                    <DisplayCancellableData isCancelled={isCancelled}>
                        <DateTimeInfo date={expectedDepartureAt} timezone={compoundLocation?.timeZone} />
                    </DisplayCancellableData>
                </LabelledInfoCell>
                <LabelledInfoCell labelMessageId="outboundOrderBook.compoundManagement.sidebar.details.carrierAndTransportType">
                    <DisplayCancellableData isCancelled={isCancelled}>
                        <DisplayFreightForwarder freightForwarder={departure.freightForwarder} />
                        {' / '}
                        <DisplayMeansOfTransportType meansOfTransportType={departure.meansOfTransport.type} />
                    </DisplayCancellableData>
                </LabelledInfoCell>
            </div>
            <DepartureCompletedForm
                timeZone={compoundLocation.timeZone}
                compoundVehicleId={compoundVehicleId}
                departureCompletedAt={undefined}
                disabled={isCancelled}
            >
                <LabelledInfoCell labelMessageId="outboundOrderBook.compoundManagement.sidebar.details.meansOfTransport">
                    <DisplayCancellableData isCancelled={isCancelled}>
                        {departure.meansOfTransport.id ?? <NoValue />}
                    </DisplayCancellableData>
                </LabelledInfoCell>
            </DepartureCompletedForm>
        </>
    );
};
