import Button from '@rio-cloud/rio-uikit/Button';
import { FunctionComponent } from 'react';
import { assertExhaustive } from '../../../../../utils';
import { NoValue } from '../../../sharedComponents/NoValue';
import { DateTime } from '../../../sharedComponents/table/BaseTableComponents';
import { OutgoingVehicle } from '../../compoundManagement.types';
import { DisplayCancellableData } from '../../shared/dataDisplay/DisplayCancellableData';
import { DisplayDispatchType } from '../../shared/dataDisplay/DisplayDispatchType';
import { DisplayFreightForwarder } from '../../shared/dataDisplay/DisplayFreightForwarder';
import { DisplayFuelType } from '../../shared/dataDisplay/DisplayFuelType';
import { DisplayMeansOfTransportType } from '../../shared/dataDisplay/DisplayMeansOfTransportType';
import { DisplayModelName } from '../../shared/dataDisplay/DisplayModelName';
import { CompoundVehicleActionButton } from '../../table/CompoundVehicleActionButton';
import { OutgoingVehiclesTableColumn } from './OutgoingVehiclesTableColumn';

type TableDataContentProps = { column: OutgoingVehiclesTableColumn; vehicle: OutgoingVehicle };

export const OutgoingVehiclesTableDataContent: FunctionComponent<TableDataContentProps> = ({ column, vehicle }) => {
    const isCancelled = vehicle.departure.isCancelled;

    switch (column) {
        case OutgoingVehiclesTableColumn.VIN:
            return <div className="ellipsis-1">{vehicle.vehicle.vin}</div>;
        case OutgoingVehiclesTableColumn.LOCATION_ID:
            return <span>{vehicle.compoundLocation.identifier}</span>;
        case OutgoingVehiclesTableColumn.LOCATION_CITY:
            return <span>{vehicle.compoundLocation.address.city}</span>;
        case OutgoingVehiclesTableColumn.EXPECTED_DEPARTURE_DATE:
            return (
                <DisplayCancellableData isCancelled={isCancelled}>
                    <DateTime
                        dateTime={vehicle.departure.expectedDepartureAt}
                        timeZone={vehicle.compoundLocation.timeZone}
                    />
                </DisplayCancellableData>
            );
        case OutgoingVehiclesTableColumn.MANUFACTURER:
            return vehicle.vehicle.model.manufacturer ?? <NoValue />;
        case OutgoingVehiclesTableColumn.MODEL:
            return <DisplayModelName vehicle={vehicle.vehicle} />;
        case OutgoingVehiclesTableColumn.DISPATCH_TYPE:
            return <DisplayDispatchType vehicle={vehicle.vehicle} />;
        case OutgoingVehiclesTableColumn.FUEL_TYPE:
            return <DisplayFuelType vehicle={vehicle.vehicle} />;
        case OutgoingVehiclesTableColumn.MODEL_VERSION:
            return vehicle.vehicle.model.version ?? <NoValue />;
        case OutgoingVehiclesTableColumn.DEPARTURE_FREIGHT_FORWARDER:
            return (
                <DisplayCancellableData isCancelled={isCancelled}>
                    <DisplayFreightForwarder
                        freightForwarder={vehicle.departure.freightForwarder}
                        className="ellipsis-1"
                    />
                </DisplayCancellableData>
            );
        case OutgoingVehiclesTableColumn.MEANS_OF_TRANSPORT:
            return (
                <DisplayCancellableData isCancelled={isCancelled}>
                    {vehicle.departure.meansOfTransport.id ?? <NoValue />}
                </DisplayCancellableData>
            );
        case OutgoingVehiclesTableColumn.MEANS_OF_TRANSPORT_TYPE:
            return (
                <DisplayCancellableData isCancelled={isCancelled}>
                    <DisplayMeansOfTransportType meansOfTransportType={vehicle.departure.meansOfTransport.type} />
                </DisplayCancellableData>
            );
        case OutgoingVehiclesTableColumn.ACTION:
            return (
                isCancelled || (
                    <CompoundVehicleActionButton
                        labelId="outboundPortal.compoundManagement.button.confirmDeparture"
                        icon="rioglyph-load-unload"
                        vehicle={vehicle}
                        buttonStyle={Button.PRIMARY}
                    />
                )
            );
        default:
            assertExhaustive(column);
    }
};
