import { ColumnDefinition } from '../../../sharedComponents/table/SortedTableHeader';

export enum OutgoingVehiclesTableColumn {
    VIN = 'vin',
    LOCATION_ID = 'locationId',
    LOCATION_CITY = 'locationCity',
    EXPECTED_DEPARTURE_DATE = 'expectedDepartureDate',
    MANUFACTURER = 'manufacturer',
    MODEL = 'model',
    DISPATCH_TYPE = 'dispatchType',
    FUEL_TYPE = 'fuelType',
    MODEL_VERSION = 'modelVersion',
    DEPARTURE_FREIGHT_FORWARDER = 'departureFreightForwarder',
    MEANS_OF_TRANSPORT_TYPE = 'meansOfTransportType',
    MEANS_OF_TRANSPORT = 'meansOfTransport',
    ACTION = 'action',
}

export const outgoingVehiclesColumnDefinition: ColumnDefinition<OutgoingVehiclesTableColumn>[] = [
    {
        column: OutgoingVehiclesTableColumn.VIN,
        labelId: 'outboundOrderBook.common.heading.vin',
        sortable: false,
    },
    {
        column: OutgoingVehiclesTableColumn.LOCATION_ID,
        labelId: 'outboundOrderBook.compoundManagement.table.heading.locationId',
        sortable: true,
    },
    {
        column: OutgoingVehiclesTableColumn.LOCATION_CITY,
        labelId: 'outboundOrderBook.compoundManagement.table.heading.location',
        sortable: false,
    },
    {
        column: OutgoingVehiclesTableColumn.EXPECTED_DEPARTURE_DATE,
        labelId: 'outboundOrderBook.compoundManagement.table.heading.expectedDepartureDate',
        sortable: true,
    },
    {
        column: OutgoingVehiclesTableColumn.MANUFACTURER,
        labelId: 'outboundOrderBook.common.heading.manufacturer',
        sortable: false,
    },
    {
        column: OutgoingVehiclesTableColumn.MODEL,
        labelId: 'outboundOrderBook.common.heading.model',
        sortable: false,
    },
    {
        column: OutgoingVehiclesTableColumn.DISPATCH_TYPE,
        labelId: 'outboundOrderBook.common.heading.vehicleType',
        sortable: false,
    },
    {
        column: OutgoingVehiclesTableColumn.FUEL_TYPE,
        labelId: 'outboundOrderBook.common.heading.fuelType',
        sortable: false,
    },
    {
        column: OutgoingVehiclesTableColumn.MODEL_VERSION,
        labelId: 'outboundOrderBook.compoundManagement.table.heading.modelVersion',
        sortable: false,
    },
    {
        column: OutgoingVehiclesTableColumn.DEPARTURE_FREIGHT_FORWARDER,
        labelId: 'outboundOrderBook.compoundManagement.table.heading.carrier',
        sortable: true,
    },
    {
        column: OutgoingVehiclesTableColumn.MEANS_OF_TRANSPORT_TYPE,
        labelId: 'outboundOrderBook.common.heading.meansOfTransportType',
        sortable: false,
    },
    {
        column: OutgoingVehiclesTableColumn.MEANS_OF_TRANSPORT,
        labelId: 'outboundOrderBook.common.heading.meansOfTransport',
        sortable: false,
    },
    {
        column: OutgoingVehiclesTableColumn.ACTION,
        labelId: 'outboundPortal.compoundManagement.table.heading.action',
        sortable: false,
    },
];
