import ExpanderList, { ExpanderListItem } from '@rio-cloud/rio-uikit/ExpanderList';
import Sidebar from '@rio-cloud/rio-uikit/Sidebar';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/hooks';
import {
    compoundManagementSlice,
    getActiveCompoundManagementView,
    getSelectedCompoundVehicle,
} from '../../../reducers/compoundManagementSlice';
import { CompoundManagementView, DepartedVehicle } from '../../compoundManagement.types';
import { ArrivalDetails, ArrivalDetailsProps } from '../../sidebar/ArrivalDetails';
import { CompoundLocationAndDestinationDetails } from '../../sidebar/CompoundLocationAndDestinationDetails';
import { CompoundVehicleVIN } from '../../sidebar/CompoundVehicleVIN';
import { VehicleDetails } from '../../sidebar/VehicleDetails';
import { DepartureDetails, DepartureDetailsProps } from './DepartureDetails';
import { DepartureDetailsHeader } from '../../sidebar/DepartureDetailsHeader';
import { CancelledArrivalDetailsHeader } from '../../sidebar/CancelledArrivalDetailsHeader';
import { ArrivalDetailsHeader } from '../../sidebar/ArrivalDetailsHeader';

export const DepartedVehiclesSidebar: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const selectedVehicle = useAppSelector(getSelectedCompoundVehicle) as DepartedVehicle;
    const activeManagementView = useAppSelector(getActiveCompoundManagementView);

    const sidebarProperties = {
        showHeaderBorder: true,
        resizable: true,
        position: Sidebar.RIGHT,
        minWidth: 400,
        maxWidth: 550,
        width: 550,
        fly: true,
    };

    const closeSidebar = () => {
        dispatch(compoundManagementSlice.actions.deselectCompoundVehicle());
    };

    if (selectedVehicle !== undefined && activeManagementView === CompoundManagementView.DEPARTED) {
        const title = <FormattedMessage id="outboundOrderBook.compoundManagement.common.sidebar.header.titleVehicle" />;

        const footerButton = (
            <div className="display-flex gap-10 flex-row-reverse">
                <button className="btn btn-default" type="button" onClick={closeSidebar}>
                    <FormattedMessage id="outboundOrderBook.common.button.close" />
                </button>
            </div>
        );

        return (
            <Sidebar
                {...sidebarProperties}
                closed={false}
                onClose={closeSidebar}
                title={title}
                titleClassName="padding-left-10"
                footer={footerButton}
                footerClassName="margin-right-10"
            >
                <CompoundVehicleVIN vehicle={selectedVehicle.vehicle} />
                <CompoundLocationAndDestinationDetails
                    compoundLocation={selectedVehicle.compoundLocation}
                    vehicle={selectedVehicle.vehicle}
                />
                <ExpanderList
                    items={[
                        buildArrivalDetailsItem({
                            compoundVehicleId: selectedVehicle.id,
                            compoundLocation: selectedVehicle.compoundLocation,
                            arrival: selectedVehicle.arrival,
                        }),
                        buildDepartureDetailsItem({
                            compoundVehicleId: selectedVehicle.id,
                            compoundLocation: selectedVehicle.compoundLocation,
                            departure: selectedVehicle.departure,
                        }),
                    ]}
                    className="margin-20"
                    unmountOnExit={false}
                />
                <VehicleDetails vehicle={selectedVehicle.vehicle} isCancelled={false} />
            </Sidebar>
        );
    } else {
        return <Sidebar {...sidebarProperties} closed />;
    }
};

const buildArrivalDetailsItem = (props: ArrivalDetailsProps): ExpanderListItem => {
    const isCancelled = props.arrival.isCancelled;

    return {
        id: 'arrival',
        open: true,
        header: isCancelled ? <CancelledArrivalDetailsHeader isWarning /> : <ArrivalDetailsHeader />,
        body: <ArrivalDetails {...props} />,
        bodyClassName: 'border-top-only border-width-1 border-style-solid border-color-light',
        className: isCancelled ? 'list-group-item-warning' : undefined,
    };
};

const buildDepartureDetailsItem = (props: DepartureDetailsProps): ExpanderListItem => {
    return {
        id: 'departure',
        open: true,
        header: <DepartureDetailsHeader />,
        body: <DepartureDetails {...props} />,
        bodyClassName: 'border-top-only border-width-1 border-style-solid border-color-light',
    };
};
